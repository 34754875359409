import React, { useCallback, useEffect, useMemo, useState } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";

import BoxReferenza from "../components/box-referenza";

import Half8Solid from "../icons/half-8-solid.svg";
import Half2Solid from "../icons/half-2-solid.svg";

const Referenze = ({ data: { referenze } }) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, []);

  const allReferenze = referenze.nodes;

  const paginatedReferenze = useMemo(() => allReferenze.slice(0, page * 6), [page, allReferenze]);
  const showMore = useCallback(() => setPage((current) => current + 1), []);

  return (
    <Layout>
      <Seo
        title={`Referenze aziendali di Intranet di successo`}
        description={`Scopri quali aziende hanno scelto Intranet 80.20 per gestire la propria comunicazione. Garantiamo soluzioni tempestive, professionali e personalizzabili.`}
      />

      <div className="hero">
        <div className="container-lg position-relative">
          <Half2Solid className="ico-svg ico-half-2-solid ico-right" />
          <Half8Solid className="ico-svg ico-half-8-solid ico-left d-none d-md-block" />
          <div className="row">
            <div className="col-10 col-lg-8">
              <h1 className="hero__title">
                Le aziende che hanno scelto <strong>Intranet 80.20</strong>
              </h1>
            </div>
          </div>
        </div>
        <div className="hero__text-scroll hero__text-scroll--classic">
          <div className="text-scroll" aria-hidden="true">
            <div className="text-scroll__wrap">
              <span className="text-uppercase referenze">Referenze</span>
            </div>
            <div className="text-scroll__wrap text-scroll__wrap--repeat">
              <span className="text-uppercase referenze">Referenze</span>
            </div>
          </div>
        </div>
      </div>

      <div className="container-lg pb-4">
        <div className="row">
          <div className="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
            <div className="row">
              {paginatedReferenze.map((item, i) => (
                <div key={i} className="col-12 col-sm-6">
                  <BoxReferenza data={item} />
                </div>
              ))}
            </div>
          </div>
        </div>

        {!!paginatedReferenze && allReferenze.length > paginatedReferenze.length && (
          <div className="row mt-3 mt-xl-5">
            <div className="col-12">
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  onClick={showMore}
                  className="btn-link btn-link--secondary showmore-click-tracking-ga"
                  data-ga-id={`mostra altre referenze`}
                >
                  carica altri
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Referenze;

export const query = graphql`
  query {
    referenze: allContentfulReferenza(sort: { fields: date, order: DESC }) {
      nodes {
        ...ReferenzaFragment
      }
    }
  }
`;
